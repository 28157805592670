<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                 <card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col cols="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" enctype="multipart/form-data">
                                            <ValidationProvider name="Phone Number" vid="mobile" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="complain_title"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('elearning_iabm.p_number')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="mobile"
                                                    v-model="complainData.mobile"
                                                    @change="getName()"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Name"  vid="complain_by" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="complain_by"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('globalTrans.name')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        disabled
                                                        v-model="complainData.complain_by"
                                                        :options="findUser"
                                                        id="complain_by"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="org_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        disabled
                                                        v-model="complainData.org_id"
                                                        id="org_id"
                                                        :options="orgList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Designation"  vid="designation" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="org_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('globalTrans.designation')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        disabled
                                                        v-model="complainData.designation"
                                                        :options="designationList"
                                                        id="designation"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Complain Title (En)" vid="complain_title" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="complain_title"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('externalTraining.complain_title_en')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="complain_title"
                                                    v-model="complainData.complain_title"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Complain Title (Bn)" vid="complain_title_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="complain_title_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('externalTraining.complain_title_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="complain_title_bn"
                                                    v-model="complainData.complain_title_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Complain Date" vid="complain_date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="complain_date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('externalTraining.complain_date')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input class="fromDate"
                                                        v-model="complainData.complain_date"
                                                        placeholder="Select Date"
                                                        id="datepicker"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Attachment" vid="attachment">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="attachment"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{$t('committee.attachment')}}
                                                </template>
                                                    <b-form-file
                                                    id="attachment"
                                                    v-on:change="onFileChange"
                                                    v-model="complainData.attachment"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Description (En)" vid="description" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="description"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('externalTraining.description_en')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-textarea
                                                    rows="3"
                                                    id="description"
                                                    v-model="complainData.description"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="description_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('externalTraining.description_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-textarea
                                                    rows="3"
                                                    id="description_bn"
                                                    v-model="complainData.description_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Remarks" vid="remarks">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="remarks"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.remarks')}}
                                                </template>
                                                    <b-form-textarea
                                                    rows="3"
                                                    id="remarks"
                                                    v-model="complainData.remarks"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { complainStore, complainUpdate, personalInfoAdmin } from '../../api/routes'
import flatpickr from 'flatpickr'
// import { mapGetters } from 'vuex'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getFormData()
            this.complainData = tmp
            // const user = this.authUser
        } else {
            this.complainData = Object.assign({}, this.complainData, {
                org_id: this.$store.state.dataFilters.orgId,
                fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
            })
        }
    },
    data () {
        return {
      loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            complainData: {
                org_id: 0,
                complain_by: 0,
                designation: 0,
                complain_title: '',
                complain_title_bn: '',
                mobile: '',
                description: '',
                description_bn: '',
                complain_date: '',
                remarks: '',
                attachment: ['']
            },
            attachmentDemo: [],
            orgData: ''
        }
    },
    computed: {
        findUser: function () {
            // return this.$store.state.TrainingElearning.commonObj.personalInfoList
           return this.$store.state.TrainingElearning.commonObj.personalInfoList.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.user_id, text: obj.text_bn }
                } else {
                return { value: obj.user_id, text: obj.text_en }
                }
            })
        },
        orgList: function () {
            return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
        },
        designationList: function () {
            return this.$store.state.commonObj.designationList.filter(item => item.status === 0)
        }
    },
    mounted () {
    flatpickr('.fromDate', {})
  },
    watch: {
    },
    methods: {
        getFormData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        onFileChange (e) {
            this.attachmentDemo = e.target.files[0]
        },
        async createData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            var formData = new FormData()
            Object.keys(this.complainData).map(key => {
                if (key === 'attachment') {
                formData.append(key, this.attachmentDemo)
                } else {
                formData.append(key, this.complainData[key])
                }
            })
            if (this.complainData.id) {
                result = await RestApi.postData(trainingElearningServiceBaseUrl, `${complainUpdate}/${this.id}`, formData, config)
            } else {
                result = await RestApi.postData(trainingElearningServiceBaseUrl, complainStore, formData, config)
            }

            loadinState.listReload = true

            this.$store.dispatch('mutateCommonProperties', loadinState)

            if (result.success) {
                this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
            this.loading = false
        },
        getName () {
            const serchData = {
                org_id: this.$store.state.dataFilters.orgId
            }
            RestApi.getData(trainingElearningServiceBaseUrl, personalInfoAdmin + this.complainData.mobile, serchData).then(response => {
                if (response.success) {
                    this.complainData.org_id = response.data.professional_info.org_id
                    this.complainData.designation = response.data.professional_info.designation_id
                    this.complainData.complain_by = response.data.user_id
                } else {
                }
            })
        }
    }
}
</script>
