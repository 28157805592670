<template>
    <b-container fluid>
         <card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('externalTraining.complain')}}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col  xs="12" sm="12" md="5">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.from_date')"
                        label-for="complain_form_date"
                        >
                        <b-form-input
                            v-model="search.complain_form_date"
                            placeholder="Select Date"
                            id="datepicker"
                        ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="5">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.to_date')"
                        label-for="complain_to_date"
                        >
                        <b-form-input
                            v-model="search.complain_to_date"
                            placeholder="Select Date"
                            id="datepicker"
                        ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <b-row>
            <b-col md="12">
                 <card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('externalTraining.complain_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" @click="pdfExport" class="mr-2">
                          {{  $t('globalTrans.export_pdf') }}
                        </b-button> -->
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(complain_date)="data">
                                          <span>{{ data.item.complain_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(attachment)="data">
                                          <a  v-if="data.item.attachment" target = '_blank' :href="baseUrl + 'download-attachment?file=uploads/complain-external/' + data.item.attachment" title="Attachment" class="mr-3" download><i class="ri-download-cloud-fill"></i></a>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-danger" v-if="data.item.status == 1">{{$t('externalTraining.not_resolved')}}</span>
                                            <span class="badge badge-success" v-if="data.item.status == 2">{{$t('externalTraining.resolved')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Active" v-if="data.item.status == 1" @click="remove(data.item)">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Inactive" v-else @click="remove(data.item)">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { complainList, complainToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Form from './Form'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                org_id: 0,
                complain_form_date: '',
                complain_to_date: ''
            },
            editItemId: 0,
            baseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
        formTitle () {
            return (this.editItemId === 0) ? this.$t('externalTraining.complain') + ' ' + this.$t('globalTrans.entry') : this.$t('externalTraining.complain_modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.name'), class: 'text-center' },
                { label: this.$t('externalTraining.complain_re_title'), class: 'text-center' },
                { label: this.$t('globalTrans.date'), class: 'text-center' },
                { label: this.$t('externalTraining.description'), class: 'text-center' },
                { label: this.$t('globalTrans.attachment'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'name_bn' },
                { key: 'complain_title_bn' },
                { key: 'complain_date' },
                { key: 'description_bn' },
                { key: 'attachment' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'name' },
                { key: 'complain_title' },
                { key: 'complain_date' },
                { key: 'description' },
                { key: 'attachment' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId
        })
        this.loadData()
    },
    mounted () {
        flatpickr('#datepicker', {})
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        resetId () {
            this.editItemId = 0
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, complainToggleStatus, item)
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, complainList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.dataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        dataList (data) {
            const personalInfoList = this.$store.state.TrainingElearning.commonObj.personalInfoList
            const getcircleAreaData = data.map(item => {
                const infoObject = personalInfoList.find(info => info.user_id === item.complain_by)
                const infoData = {
                    name: infoObject ? infoObject.text : '',
                    name_bn: infoObject ? infoObject.text_bn : ''
                }
                return Object.assign({}, item, infoData)
            })
            return getcircleAreaData
        }
    }
}
</script>
